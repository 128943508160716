<template>
  <div class="collection-container">
    <Top></Top>
     <Fix></Fix>
     <div class="bread-box">
        <div class="bread-card">
          <el-breadcrumb class="bread-card-crumb" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/recome'}">AI商标注册</el-breadcrumb-item>
            <el-breadcrumb-item>我的类别收藏</el-breadcrumb-item>
          </el-breadcrumb>
         </div>
      </div>
     <div class="collection-box">
         <div class="collection-title">
             <div :class="collectActive">
                 <div class="div1" @click="collectChange('you')">有商标类别</div>
                 <div class="div2" @click="collectChange('wu')">无商标类别</div>
             </div>
         </div>
         <div class="collection-bigbox">
             <div class="collection-list">
                 <div class="collection-checkbox">
                     <div>
                         <el-checkbox class="collection-checkbox-input"></el-checkbox>
                     </div>
                 </div>
                 <div class="collection-img">
                     <img :src="require('/static/images/2.png')" alt="">
                 </div>
                 <div class="collection-inf">
                     <div class="collection-name">海底捞</div>
                     <div class="collection-bigtype">
                         <div class="collection-bigtype-list" v-for="(v,k) in 2">
                             <div class="collection-list-detail">
                                 第01类 化学原料 <i class="el-icon-arrow-up"></i>
                             </div>
                             <div class="collection-list-little">
                                 <span class="little-span" v-for="(v,k) in 12">010650 电泳凝胶</span>
                                 <span>收起</span>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div class="collection-use">
             <div>使用</div>
         </div>
     </div>
     <Foot></Foot>
  </div>    
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name: 'collection',
  data(){
    return{
      collectActive:'you'
    }
  },
  watch:{
           

  },
  components: {
       Top,
      Foot,
      Fix
  },
  mounted(){
    
  },
  methods:{
    collectChange(val){
        this.collectActive = val
    }
  }
}

</script>

<style scoped>
.bread-box{
    width: 100%;
    height: 40px;
    background-color: #f8f8f8;
  }
  .bread-card{
    width: 1200px;
    height: 28px;
    margin: 0 auto;
    padding-top:12px;
  }
  .collection-container{
      background: #fcfcfc;
  }
    .collection-box{
        padding-top: 31px;
    }
    .collection-title{
        height: 40px;
        margin-bottom: 30px;
        border-bottom:2px solid #EB5E00;
    }
    .collection-title>div{
         width: 1200px;
        margin: 0 auto;
        height: 40px;
    }
    .collection-title>div>div{
        float: left;
        width: 120px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        color: #000000;
        cursor: pointer;
    }
    .collection-title>div>div:nth-of-type(1){
        margin-right: 10px;
    }
    .you .div1,.wu .div2{
        color: #ffffff;
       background: #EB5E00;
    }
    .collection-bigbox{
        width: 1200px;
        margin: 0 auto;
    }
    .collection-list{
        width: 1200px;
        /* border:1px solid red; */
        margin-bottom: 30px;
        padding: 40px 0;
        box-shadow: 0px 4px 10px 0px #F2F2F2;   
        overflow: hidden;
        background:#FFFFFF;;
    }
    .collection-list>div{
        float: left;
    }
    .collection-checkbox{
        width: 96px;
        height: 120px;
    }
    .collection-checkbox>div{
        width: 16px;
        height: 120px;
    }
    .collection-checkbox-input{
        width: 16px;
        height: 16px;
        margin-top: 52px;
        margin-left: 40px;
        cursor: pointer;
    }
    .collection-img{
        width: 120px;
        height: 120px;
        border:1px solid #D8D8D8;
        margin-right: 40px;
    }
    .collection-img>img{
        width: 120px;
        height: 120px;
    }
    .collection-inf{
        width: 880px;
    }
    .collection-name{
        font-size: 18px;
        margin-bottom: 18px;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
    }
    .collection-bigtype{
        width: 880px;
        /* border:1px solid red; */
    }
    .collection-bigtype-list{
        width: 880px;
        margin-bottom: 20px;
    }
    .collection-list-detail{
      font-size: 14px;
      color: #000000;
      margin-bottom: 12px;
    }
    .collection-list-detail>i{
      margin-left: 5px;
      cursor: pointer;
    }
    .collection-list-little{
        width: 828px;
        margin-left: 52px;
        /* border:1px solid red; */
    }
    .little-span{
        display: inline-block;
       padding: 0 9px;
       height: 22px;
       border: 1px solid #d8d8d8;
       margin: 0 10px 4px 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color:#A1A1A1;
    }
    .collection-list-little>span:last-child{
        color: #F96009;
        cursor: pointer;
        font-size: 14px;
    }
    .collection-use{
        width: 1200px;
        padding-top: 30px;
        margin: 0 auto 50px;
    }
    .collection-use>div{
        cursor: pointer;
        width: 214px;
        height: 54px;
        background: #F96009;
        border-radius: 3px;
        margin: 0 auto;
        line-height: 54px;
        text-align: center;
        font-size: 18px;
        color: #FFFFFF;
    }
</style>
<style>
  .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
      font-weight: normal;
  }
  .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link:hover{
      color:#303133;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #EB5E00;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #EB5E00;
    border-color: #EB5E00;
  }
</style>
